/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { convertHexToRGBA } from 'utils/convertHexToRgba';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding-bottom: 0;
    width: 100%;
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-weight: ${theme.style?.fieldLabel?.fontWeight};
    position: ${theme.style?.fieldLabel?.position};
    color: ${theme.style?.fieldLabel?.color};
    top: ${theme.style?.fieldLabel?.top};
    left: ${theme.style?.fieldLabel?.left};
    text-transform: ${theme.style?.fieldLabel?.textTransform};
    letter-spacing: ${theme.style?.fieldLabel?.letterSpacing};
    transition: ${theme.style?.fieldLabel?.transition};
    font-size: ${theme.style?.fieldLabel?.fontSize};
    z-index: ${theme.style?.fieldLabel?.zIndex};
    background-color: ${theme.style?.fieldLabel?.backgroundColor};
    margin: ${theme.style?.fieldLabel?.margin};
    font-family: ${theme.style?.fieldLabel?.fontFamily};
    display: block;
    padding: ${theme.style?.fieldLabel?.padding};
    flex-wrap: ${theme.style?.fieldLabel?.flexWrap};
    flex-direction: ${theme.style?.fieldLabel?.flexDirection};

    ${labelModifiers.updateComponent(theme)}
  `}
`;

export const Input = styled.div`
  ${({ theme, selectedChild, hasError }) => css`
    border: ${theme.style?.input?.border};
    border-radius: ${theme.style?.input?.borderRadius};
    border-bottom: ${theme.style?.input?.borderBottom};
    transition: none;

    .PhoneInputCountry {
      padding: ${theme.style?.input?.padding};
    }

    .PhoneInputCountrySelectArrow {
      color: ${theme.style?.input?.color};
    }

    .PhoneInputInput {
      border: none;
      width: 100%;
      background-color: ${theme.style?.input?.backgroundColor};
      font-size: ${theme.style?.input?.fontSize};
      font-weight: ${theme.style?.input?.fontWeight};
      box-shadow: none;
      color: ${theme.style?.input?.color};
      font-family: ${theme.style?.input?.fontFamily};
      transition: none;
      height: ${theme.style?.input?.height};
      line-height: ${theme.style?.input?.lineHeight};
      padding-left: ${theme.style?.input?.paddingLeft};
      padding: ${theme.style?.input?.padding};
      padding-top: ${theme.style?.input?.paddingTop};
      margin-top: ${theme.style?.input?.marginTop};
      box-sizing: border-box;
      padding-left: 5px;
      outline: none;

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        border-radius: ${theme.style?.input?.autofill.borderRadius};
        caret-color: ${theme.style?.input?.autofill.webkitTextFillColor};
        -webkit-text-fill-color: ${theme.style?.input?.autofill
          .webkitTextFillColor};
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
      }

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    .PhoneInput {
      border: 1px solid transparent;

      background-color: ${convertHexToRGBA(
        theme.color.neutral.pure,
        theme.opacity.light
      )};

      border-radius: ${theme.border.radius.sm};
      border-bottom: ${theme.border.width.none};
    }

    .PhoneInputCountry {
      padding: ${theme.font.size.xxs} ${theme.spacing.xxxs};
      margin: 0;
    }

    .PhoneInputCountrySelectArrow {
      color: ${theme.color.neutral.pure};
    }

    .PhoneInputInput {
      background: transparent;
      font-size: ${theme.font.size.xs};
      font-weight: ${theme.font.weight.regular};
      color: ${theme.color.neutral.pure};
      font-family: ${theme.font.family.primary};
      height: ${theme.spacing.md};
      padding: ${theme.font.size.xxs} ${theme.spacing.xxxs}
        ${theme.font.size.xxs} 0;

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        border-radius: ${theme.border.radius.sm};
        caret-color: ${theme.color.neutral.pure};
        -webkit-text-fill-color: ${theme.color.neutral.pure};
      }

      &::placeholder {
        color: ${convertHexToRGBA(
          theme.color.neutral.pure,
          theme.opacity.semiopaque
        )};

        @-moz-document url-prefix() {
          color: ${convertHexToRGBA(theme.color.neutral.pure, 0.48)};
        }
      }
    }

    box-shadow: none;
    height: ${theme.spacing.md};

    :hover {
      border-radius: ${theme.border.radius.sm};

      .PhoneInput {
        background-color: transparent;
      }

      background-color: ${convertHexToRGBA(
        theme.color.neutral.pure,
        theme.opacity.regular
      )};
    }

    ${hasError &&
    css`
      outline: none;
      border-radius: ${theme.border.radius.sm};
      margin-bottom: 8px;

      .PhoneInput {
        border: ${theme.border.width.hairline} solid
          ${theme.color.feedback.error.pure};
      }
    `}

    ${selectedChild &&
    css`
      border-radius: ${theme.border.radius.sm};

      .PhoneInput {
        border: ${theme.border.width.hairline} solid
          ${theme.color.neutral.highContrast};
      }

      .PhoneInputCountry {
        padding-left: ${theme.spacing.insetXs};
      }
    `} 

    ${theme.data.lang === 'pt-MZ' &&
    inputModifiers.isMozambiqueTheme(theme, selectedChild, hasError)}
  `}
`;

export const Error = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 0;
    span {
      font-size: ${theme.style?.error?.errorMsg?.fontSize};
      display: ${theme.style?.error?.errorMsg?.display};
      color: ${theme.style?.error?.errorMsg?.color};
      margin: ${theme.style?.error?.errorMsg?.margin};
      padding: ${theme.style?.error?.errorMsg?.padding};
      position: ${theme.style?.error?.errorMsg?.position};
    }
  `}
`;

const labelModifiers = {
  updateComponent: (theme) => css`
    margin: 0;
    margin-bottom: ${theme.spacing.insetNano};
  `,
};

const inputModifiers = {
  isMozambiqueTheme: (theme, onFocus, hasError) => css`
    .PhoneInputInput {
      font-size: 14px;

      ::placeholder {
        color: #727473;
      }
    }

    .PhoneInput {
      background-color: ${theme.style?.input?.backgroundColor};
      border: 2px solid transparent;
    }

    ${hasError &&
    css`
      .PhoneInput {
        border: ${theme.border.width.hairline} solid
          ${theme.color.feedback.error.pure};
      }

      ${onFocus &&
      css`
        .PhoneInputCountry {
          padding: 14px 16px 14px 15px;
        }

        .PhoneInput {
          height: 50px;
        }
      `}
    `}

    ${onFocus &&
    css`
      .PhoneInput {
        border: ${theme.style.input.focus.outline};
      }
    `}

    :hover {
      background-color: ${theme.style?.input?.backgroundColor};

      .PhoneInput {
        background-color: ${theme.style?.input?.backgroundColor};
      }
    }
  `,
};
