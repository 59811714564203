import dayjs from 'dayjs';

// date: 2024-01-01
export const unformatDate = (date: string) => {
  const dateSplitted = date.split('-').reverse();
  const dateFormatted = dateSplitted.join('/');

  return dateFormatted;
};

export const isLegalAge = (birthDate: string) => {
  const currentDate = new Date();

  const splitDate = birthDate?.split('/').map((item) => Number(item));
  const [day, month, year] = splitDate;

  const birthDateFormatted = new Date(year, month, day);

  const diffYears = dayjs(currentDate).diff(birthDateFormatted, 'years');

  return diffYears >= 18;
};

export function getFormatDate(date: string) {
  const formattedDate = date.split('/').reverse().join('-');
  return formattedDate;
}
