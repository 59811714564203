import React from 'react';

import PropTypes from 'prop-types';

const IconAlert = React.memo(({ color, ...props }) => {
  return (
    <div style={{ width: '22px', height: '22px' }}>
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <circle cx="11.0003" cy="10.9993" r="10.0833" fill={color} />
        <path
          d="M10.083 13.7512C10.083 13.6309 10.1067 13.5118 10.1528 13.4006C10.1989 13.2895 10.2664 13.1885 10.3515 13.1034C10.4366 13.0184 10.5377 12.9509 10.6489 12.9049C10.7601 12.8588 10.8793 12.8351 10.9997 12.8351C11.1201 12.8351 11.2393 12.8588 11.3505 12.9049C11.4617 12.9509 11.5627 13.0184 11.6479 13.1034C11.733 13.1885 11.8005 13.2895 11.8466 13.4006C11.8926 13.5118 11.9163 13.6309 11.9163 13.7512C11.9163 13.9942 11.8198 14.2272 11.6479 14.399C11.4759 14.5708 11.2428 14.6673 10.9997 14.6673C10.7566 14.6673 10.5234 14.5708 10.3515 14.399C10.1796 14.2272 10.083 13.9942 10.083 13.7512ZM10.1728 8.25008C10.1606 8.13451 10.1729 8.01766 10.2088 7.90712C10.2447 7.79658 10.3034 7.69482 10.3812 7.60843C10.459 7.52205 10.5541 7.45298 10.6603 7.4057C10.7666 7.35842 10.8816 7.33398 10.9978 7.33398C11.1141 7.33398 11.2291 7.35842 11.3353 7.4057C11.4416 7.45298 11.5367 7.52205 11.6145 7.60843C11.6923 7.69482 11.751 7.79658 11.7869 7.90712C11.8228 8.01766 11.835 8.13451 11.8228 8.25008L11.502 11.4628C11.4912 11.589 11.4334 11.7066 11.3401 11.7923C11.2467 11.878 11.1246 11.9255 10.9978 11.9255C10.8711 11.9255 10.749 11.878 10.6556 11.7923C10.5622 11.7066 10.5045 11.589 10.4937 11.4628L10.1728 8.25008Z"
          fill="#000000"
        />
      </svg>
    </div>
  );
});

IconAlert.propTypes = {
  color: PropTypes.string,
};

IconAlert.defaultProps = {
  color: '#ff598a',
};

export default IconAlert;
