/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import dynamic from 'next/dynamic';

import { validatePhone } from 'utils/PhoneNumberValidation';
import * as validate from 'utils/inputValidate';
import CartContext from 'contexts/CartContext';
import { Wrapper, Label, Input, Error } from './styles';

const DsTypography = dynamic(() => import('componentsV2/DS/Typography'), {
  ssr: false,
});

const PhoneNumberInput = ({
  label,
  placeholder,
  defaultCountry,
  value,
  onChange,
  onBlur,
  maxLength,
  dataCy,
  dataCyErrorMessage,
  ref,
  fieldtype,
  hasError,
  helperText,
}) => {
  const [selectedChild, setSelectedChild] = useState(false);
  const context = useContext(CartContext);
  const [errorMessage, setErrorMessage] = useState(helperText);
  const [numberValue, setNumberValue] = useState(value || '');
  const [showSuccessBorder, setShowSuccessBorder] = useState(false);

  const handleErrorMessage = (showError = true) => {
    const validateFunction = validate.inputValidate;

    const validateObj = validateFunction(
      fieldtype,
      value,
      context.productTheme.data.lang,
      context?.product
    );

    const errorMessageByProduct = {
      EM: 'Insira o número do seu celular com DDD.',
      default:
        'Insira o DDD e um número de celular válido. Caso seja de fora do Brasil, selecione a bandeira no ícone ao lado.',
    };

    if (showError) {
      const error = context?.isMozambiqueTheme
        ? 'Seleccione a bandeira do seu país no ícone ao lado e insira um número de telemóvel válido com DDD'
        : errorMessageByProduct[context?.product] ||
          errorMessageByProduct.default;

      setErrorMessage(validateObj.errorMessage || error);
    } else setErrorMessage('');
    return validateObj;
  };

  const handleOnChange = (inputValue) => {
    setNumberValue(inputValue);

    return onChange(inputValue);
  };

  const handleBlur = () => {
    setSelectedChild(false);

    if (typeof onBlur === 'function') {
      onBlur();
    }

    if (numberValue) {
      const isValid = !!validatePhone(
        numberValue,
        parsePhoneNumber(numberValue || '')?.country
      );

      if (!isValid) {
        const showError = true;
        handleErrorMessage(showError);
        setShowSuccessBorder(false);
      } else {
        const showError = false;
        handleErrorMessage(showError);
        setShowSuccessBorder(true);
      }
    } else if (numberValue === undefined) {
      setShowSuccessBorder(false);
    }
  };

  useEffect(() => {
    if (hasError) {
      handleErrorMessage(true);
    }
  }, [hasError]);

  useEffect(() => {
    if (helperText) {
      handleErrorMessage(true);
    }

    setErrorMessage(helperText);
  }, [helperText]);

  useEffect(() => {
    if (value) {
      const isValid = !!validatePhone(
        value,
        parsePhoneNumber(value || '')?.country
      );
      if (isValid) {
        setShowSuccessBorder(true);
      } else {
        handleErrorMessage(true);
      }
    }
  }, []);

  return (
    <Wrapper>
      <Label htmlFor="phone">
        <DsTypography
          component="paragraph"
          size="small-bold"
          color="neutral-pure"
        >
          {label}
        </DsTypography>
      </Label>

      <Input
        id="phone"
        data-testid="phoneInputBox"
        hasError={!!errorMessage}
        showSuccessBorder={showSuccessBorder}
        selectedChild={selectedChild}
        isThemeAvailableOnDs={!context?.isMozambiqueTheme}
      >
        <PhoneInput
          id="phone"
          data-testid="phone"
          ref={ref}
          placeholder={placeholder}
          defaultCountry={defaultCountry}
          value={value}
          onChange={(number) => handleOnChange(number)}
          maxLength={maxLength}
          data-cy={dataCy}
          onFocus={() => setSelectedChild(true)}
          onBlur={handleBlur}
        />
      </Input>
      {(!!errorMessage || hasError) && (
        <Error data-testid="error-message">
          <span data-cy={dataCyErrorMessage}>
            <DsTypography
              component="paragraph"
              size="small"
              color="feedback-error-pure"
            >
              {errorMessage}
            </DsTypography>
          </span>
        </Error>
      )}
    </Wrapper>
  );
};

PhoneNumberInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  defaultCountry: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  dataCy: PropTypes.string,
  dataCyErrorMessage: PropTypes.string,
  ref: PropTypes.shape({}),
  fieldtype: PropTypes.string,
  hasError: PropTypes.bool,
  helperText: PropTypes.string,
};

PhoneNumberInput.defaultProps = {
  label: 'Celular',
  placeholder: 'Ex: 41 99999 9999',
  defaultCountry: 'BR',
  value: '',
  onChange: {},
  onBlur: {},
  maxLength: 25,
  dataCy: '',
  dataCyErrorMessage: '',
  ref: null,
  fieldtype: '',
  hasError: false,
  helperText: '',
};

export default PhoneNumberInput;
