import React, { HTMLAttributes } from 'react';
import dynamic from 'next/dynamic';

import type { ButtonProps, IButton } from './Button/Button';
import type { ITextField, TextFieldProps } from './TextField/TextField';
import type { CheckboxProps, ICheckbox } from './Checkbox/Checkbox';
import { ISelect } from './Select/Select';
import type { TypographyProps } from './Typography/Typography';
import type { IconProps } from './Icon/Icon';
import { SelectProps } from '@wisereducacao-npm/design-system-core-web/dist/components/select/types';
import { CardBaseProps } from '@wisereducacao-npm/design-system-core-web/dist/components/card-base/types';
import type { RadioProps } from '@wisereducacao-npm/design-system-core-web/dist/components/radio/types';
import { ProviderProps } from '@wisereducacao-npm/design-system-brands-web/dist/components/provider/types';

const Button = dynamic<ButtonProps>(() => import('componentsV2/DS/Button'), {
  ssr: false,
}) as React.FC<ButtonProps>;

export const DsButton = ({ children, ...props }: IButton & ButtonProps) => {
  return <Button {...props}>{children}</Button>;
};

const TextField = dynamic<TextFieldProps>(
  () => import('componentsV2/DS/TextField'),
  {
    ssr: false,
  }
) as React.FC<TextFieldProps>;

export const DsTextField = ({
  ...props
}: ITextField &
  Omit<TextFieldProps, 'autocomplete'> & {
    autocomplete?: string;
  }) => {
  // @ts-ignore
  return <TextField {...props} />;
};

const Checkbox = dynamic<ICheckbox & Omit<CheckboxProps, 'pulse'>>(
  () => import('componentsV2/DS/Checkbox'),
  {
    ssr: false,
  }
) as React.FC<ICheckbox & Omit<CheckboxProps, 'pulse'>>;

export const DsCheckbox = ({
  children,
  ...props
}: ICheckbox & Omit<CheckboxProps, 'pulse'>) => {
  return children ? (
    <Checkbox {...props}>{children}</Checkbox>
  ) : (
    <Checkbox {...props} />
  );
};

const Typography = dynamic<TypographyProps>(
  () => import('componentsV2/DS/Typography'),
  {
    ssr: false,
  }
) as React.FC<TypographyProps & HTMLAttributes<HTMLParagraphElement>>;

export const DsTypography = ({
  children,
  ...props
}: TypographyProps & HTMLAttributes<HTMLParagraphElement>) => {
  return children ? (
    <Typography {...props}>{children}</Typography>
  ) : (
    <Typography {...props} />
  );
};

const Icon = dynamic<IconProps>(() => import('componentsV2/DS/Icon'), {
  ssr: false,
}) as React.FC<IconProps>;

export const DsIcon = ({ ...props }: IconProps) => {
  return <Icon {...props} />;
};

const Select = dynamic<ISelect & Omit<SelectProps, 'onChange'>>(
  () => import('componentsV2/DS/Select'),
  {
    ssr: false,
  }
) as React.FC<ISelect & Omit<SelectProps, 'onChange'>>;

export const DsSelect = ({
  ...props
}: ISelect & Omit<SelectProps, 'onChange'>) => {
  return <Select {...props} />;
};

const CardBase = dynamic<CardBaseProps>(
  () => import('componentsV2/DS/CardBase'),
  {
    ssr: false,
  }
) as React.FC<CardBaseProps>;

export const DsCardBase = ({ children, ...props }: CardBaseProps) => {
  return children ? (
    <CardBase {...props}>{children}</CardBase>
  ) : (
    <CardBase {...props} />
  );
};

const ExpandableCard = dynamic<CardBaseProps>(
  () => import('componentsV2/DS/ExpandableCard'),
  {
    ssr: false,
  }
) as React.FC<CardBaseProps>;

export const DsExpandableCard = ({ children, ...props }) => {
  return children ? (
    <ExpandableCard {...props}>{children}</ExpandableCard>
  ) : (
    <ExpandableCard {...props} />
  );
};

export const Radio = dynamic(() => import('componentsV2/DS/Radio'), {
  ssr: false,
}) as React.FC<RadioProps>;

export const DsRadio = ({ ...props }: RadioProps) => {
  return <Radio {...props} />;
};

const Provider = dynamic<ProviderProps>(
  () => import('componentsV2/DS/Provider'),
  {
    ssr: false,
  }
) as React.FC<ProviderProps>;

export const DsProvider = ({ children, ...props }: ProviderProps) => {
  return <Provider {...props}>{children}</Provider>;
};
