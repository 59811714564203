import axios from 'axios';

// adapters
import { logger } from 'adapters/Logger';

export const getIps = async (): Promise<{
  ipv4: string;
  ipv6: string;
}> => {
  try {
    const [responseIpv4, responseIpv6] = await Promise.all([
      axios.get('https://api.ipify.org?format=json'),
      axios.get('https://api64.ipify.org?format=json'),
    ]);

    return { ipv4: responseIpv4.data.ip, ipv6: responseIpv6.data.ip };
  } catch (error) {
    console.error(JSON.stringify(error));

    const url = error.config?.url;
    const method = error.config?.method;
    const statusCode = error.response?.status;

    logger.error(error, undefined, {
      requestUrl: url,
      statusCode,
      requestMethod: method,
    });

    return { ipv4: '', ipv6: '' };
  }
};
