import React from 'react';

import PropTypes from 'prop-types';

const iconCheckCircle = React.memo(({ color, ...props }) => {
  return (
    <div style={{ width: '22px', height: '22px' }}>
      <svg
        {...props}
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.125 10.0195L10.6219 15.2695L7.875 12.6445"
          stroke="#0CF26C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 21.2695C16.9705 21.2695 21 17.2401 21 12.2695C21 7.29897 16.9705 3.26953 12 3.26953C7.02942 3.26953 2.99998 7.29897 2.99998 12.2695C2.99998 17.2401 7.02942 21.2695 12 21.2695Z"
          stroke="#0CF26C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
});

iconCheckCircle.propTypes = {
  color: PropTypes.string,
};

iconCheckCircle.defaultProps = {
  color: '#0CF26C',
};

export default iconCheckCircle;
