import React from 'react';
import dynamic from 'next/dynamic';

import {
  Wrapper,
  ShieldContainer,
  GooglePoliticsAndTermsContainer,
  SealsContainer,
} from './styles';

const DsTypography = dynamic(() => import('componentsV2/DS/Typography'), {
  ssr: false,
});

const DsIcon = dynamic(() => import('componentsV2/DS/Icon'), {
  ssr: false,
});

function SecurityFooter({ showShieldText = true, showSeals = false, dataCy }) {
  return (
    <Wrapper>
      {showShieldText && (
        <ShieldContainer data-cy={`@${dataCy}/security-place-message`}>
          <DsIcon icon="shield_check" size="small" />
          <DsTypography component="paragraph" size="small">
            Ambiente <strong>100%</strong> seguro
          </DsTypography>
        </ShieldContainer>
      )}
      <GooglePoliticsAndTermsContainer
        data-cy={`@${dataCy}/recaptcha-sucurity-message`}
      >
        <DsTypography component="paragraph" size="small" color="neutral-high">
          Este site é protegido pelo reCAPTCHA e aplicam-se a{' '}
          <a href="https://policies.google.com/privacy" target="_blank">
            Política de Privacidade
          </a>{' '}
          e os{' '}
          <a href="https://policies.google.com/terms" target="_blank">
            Termos de Serviço
          </a>{' '}
          do Google.
        </DsTypography>
      </GooglePoliticsAndTermsContainer>
      {showSeals && (
        <SealsContainer>
          <img src="/static/sectigo-seal.png" alt="sectigo seal" />
          <img src="/static/cloudflare-seal.png" alt="cloudflare seal" />
        </SealsContainer>
      )}
    </Wrapper>
  );
}
export default SecurityFooter;
