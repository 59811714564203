import React from 'react';

import { Wrapper, Label } from './styles';

export function Checkbox({
  children,
  name,
  value,
  onChange,
  checked,
  className,
  id,
  dataCy,
  linkColor = '',
  startPulseAnimation = false,
  checkboxTestId = '',
}) {
  return (
    <Wrapper
      linkColor={linkColor}
      className={className}
      startPulseAnimation={startPulseAnimation}
    >
      <Label data-testid="label-terms-checkbox" htmlFor={id}>
        {children}

        <input
          data-testid={checkboxTestId}
          autoComplete="off"
          type="checkbox"
          onChange={onChange}
          name={name}
          value={value}
          checked={checked}
          data-cy={dataCy}
          id={id}
        />
        <span className="checkmark" />
      </Label>
    </Wrapper>
  );
}

export default Checkbox;
