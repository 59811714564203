// import Axios from 'axios';

const clientIP = async () => {
  // const apiIpv4 = 'https://api.ipify.org';
  // const apiIpv6 = 'https://api64.ipify.org';
  // const ipv4 = await Axios.get(apiIpv4).then((res) => res.data);
  // const ipv6 = await Axios.get(apiIpv6).then((res) => res.data);

  return {
    ipv4: '191.177.184.62',
    ipv6: '191.177.184.62',
  };
};

export default clientIP;
export { clientIP };
