import axios from 'axios';
import cookies from 'js-cookie';

// adapters
import { logger } from 'adapters/Logger';

export const apiBase = (call, extras) => {
  const api = axios.create({
    baseURL: `/`,
  });
  api.defaults.timeout = 60000;

  const userId = cookies.get('_ga') || extras?.cookies?._ga;
  const userEmail = cookies.get('email') || extras?.cookies?.email;

  const ignoredUrlRequestsErrors = ['/SendToAnalytics'];

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.error('action interceptor error: ', error?.response?.data);

      const url = error.config?.url;
      const method = error.config?.method;
      const statusCode = error.response?.status;

      if (!ignoredUrlRequestsErrors.includes(call?.url)) {
        logger.error(error, undefined, {
          userId,
          userEmail,
          requestUrl: url,
          requestMethod: method,
          statusCode,
        });
      }

      return Promise.reject(error);
    }
  );

  return api(call);
};
