import React from 'react';

import { Wrapper, StepItem } from './styles';

interface StepsProps {
  currentStep?: number;
  stepsNumber?: number;
  color?: string;
  inactiveBg?: string;
}

const Steps = ({
  currentStep = 1,
  stepsNumber = 4,
  color,
  inactiveBg,
  ...props
}: StepsProps) => {
  const DEFAULT_STEPS_NUMBER = 4;
  const totalSteps = stepsNumber || DEFAULT_STEPS_NUMBER;
  const stepsArray = Array.from(Array(totalSteps).keys());

  return (
    <Wrapper {...props}>
      {stepsArray?.map((item) => (
        <StepItem
          key={item}
          disabled={item + 1 > currentStep}
          isActive={currentStep >= item + 1}
          color={color}
          inactiveBg={inactiveBg}
        />
      ))}
    </Wrapper>
  );
};

export default Steps;
