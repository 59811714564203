import styled, { css } from 'styled-components';
import { convertHexToRGBA } from 'utils/convertHexToRgba';

export const Wrapper = styled.div`
  width: 100%;
  height: 8px;
  display: flex;
  justify-content: space-between;
`;

const stepItemModifiers = {
  isThemeUsingAllDsTokens: (theme, isActive) => css`
    background-color: ${isActive
      ? theme.color?.secondary?.pure
      : convertHexToRGBA(theme.color.neutral.pure, theme.opacity.light)};
  `,
};

interface StepItemProps {
  theme: any;
  isActive: boolean;
  disabled: boolean;
  color: string;
  inactiveBg: string;
}

export const StepItem = styled.div<StepItemProps>`
  ${({ theme, isActive, color, inactiveBg }) => css`
    width: 100%;
    height: 100%;
    margin-right: 16px;
    border-radius: 4px;
    background-color: ${isActive
      ? color || theme.style?.buttons?.backgroundColor
      : inactiveBg || theme.style?.steps?.backgroundColor};

    &:last-child {
      margin-right: 0;
    }

    ${!!theme.data?.isThemeUsingAllDsTokens &&
    stepItemModifiers.isThemeUsingAllDsTokens(theme, isActive)}
  `}
`;
