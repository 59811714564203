import styled, { css } from 'styled-components';

const wrapperModifiers = {
  updateComponent: (theme, linkColor, startPulseAnimation) => css`
    p {
      letter-spacing: 0;
      font-size: 14px;
      line-height: 18px;
    }

    .checkmark {
      box-shadow: 0 0 0
        ${linkColor ||
        theme.style.lead.checkboxPolitica.textBoxPolitica.colorLink};
      animation: ${startPulseAnimation ? 'pulse 2s infinite' : 'none'};
    }

    @-webkit-keyframes pulse {
      0% {
        -webkit-box-shadow: 0 0 0 0
          ${linkColor ||
          theme.style.lead.checkboxPolitica.textBoxPolitica.colorLink};
      }
      70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
      }
      100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      }
    }
    @keyframes pulse {
      0% {
        -moz-box-shadow: 0 0 0 0
          ${linkColor ||
          theme.style.lead.checkboxPolitica.textBoxPolitica.colorLink};
        box-shadow: 0 0 0 0
          ${linkColor ||
          theme.style.lead.checkboxPolitica.textBoxPolitica.colorLink};
      }
      70% {
        -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
      }
      100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      }
    }
  `,
};

export const Wrapper = styled.div`
  ${({ theme, linkColor, startPulseAnimation }) => css`
    margin: 0;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        border: 1px solid ${linkColor || theme.color.secondary.pure};
        animation: none;
      }
    }

    p {
      margin: 0;
      font-size: 12px;
      color: ${theme.color.neutral.pure};
      letter-spacing: 1.4px;
    }

    p a {
      color: ${linkColor ||
      theme.style.lead.checkboxPolitica.textBoxPolitica.colorLink};
      text-decoration: underline;
    }

    .checkmark {
      position: absolute;

      top: 4px;
      left: 0;
      bottom: 0;
      right: 0;
      height: 10px;
      box-sizing: initial;
      width: 10px;
      border-radius: 4px;
      border: 1px solid #c4c4c4;
    }

    &:hover input ~ .checkmark {
      /* background-color: #ccc; */
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 4px;
      right: 0;
      margin: 0 auto;
      top: -4px;
      width: 3px;
      height: 10px;
      border: solid ${linkColor || theme.color.secondary.pure};
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      box-sizing: initial;
    }

    ${wrapperModifiers.updateComponent(theme, linkColor, startPulseAnimation)}
  `}
`;

export const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
