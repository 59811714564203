import Cookies from 'js-cookie';

const utmKeys = [
  'utm_campaign',
  'utm_content',
  'utm_creative_format',
  'utm_id',
  'utm_marketing_tactic',
  'utm_medium',
  'utm_source',
  'utm_source_platform',
  'utm_term',
];

function formatUtm(utmKey: string) {
  const parts = utmKey.split('_');

  const camelCased = parts
    .map((part, index) =>
      index === 0 ? part : part.charAt(0).toUpperCase() + part.slice(1)
    )
    .join('');
  return camelCased;
}

export function getUtmMetadata(routerQuery: Record<string, string>) {
  let utmMetadataObj = {} as Record<string, string>;

  for (const key in routerQuery) {
    if (key.includes('utm')) {
      Cookies.set(key, routerQuery[key]);

      utmMetadataObj[formatUtm(key)] = routerQuery[key];
    }
  }

  if (!Object.keys(utmMetadataObj).length) {
    utmMetadataObj = utmKeys.reduce((acc, current) => {
      return {
        ...acc,
        [formatUtm(current)]: Cookies.get(current),
      };
    }, utmMetadataObj);
  }

  return utmMetadataObj;
}
