import React from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';

const ButtonStyle = ({ theme }) => css`
  -webkit-appearance: ${theme.style?.buttons?.webkitAppearance};
  -moz-appearance: ${theme.style?.buttons?.mozAppearance};
  appearance: ${theme.style?.buttons?.appearance};
  -webkit-border-radius: ${theme.style?.buttons?.webkitBorderRadius};
  -khtml-border-radius: ${theme.style?.buttons?.khtmlBorderRadius};
  -moz-border-radius: ${theme.style?.buttons?.mozBorderRadius};
  -ms-border-radius: ${theme.style?.buttons?.msBorderRadius};
  -o-border-radius: ${theme.style?.buttons?.oBorderRadius};
  border-radius: ${theme.style?.buttons?.borderRadius};
  background-color: ${theme.style?.buttons?.backgroundColor};
  font-size: ${theme.style?.buttons?.fontSize};
  font-weight: ${theme.style?.buttons?.fontWeight};
  text-align: ${theme.style?.buttons?.textAlign};
  color: ${theme.style?.buttons?.color};
  height: ${theme.style?.buttons?.height};
  display: ${theme.style?.buttons?.display};
  border: ${theme.style?.buttons?.border};
  font-family: ${theme.style?.buttons?.fontFamily};
  cursor: ${theme.style?.buttons?.cursor};
  margin: ${theme.style?.buttons?.margin};
  width: ${theme.style?.buttons?.width};
  text-transform: ${theme.style?.buttons?.textTransform};
  line-height: ${theme.style?.buttons?.lineHeight};
  letter-spacing: ${theme.style?.buttons?.letterSpacing};
  outline: none;
  // transition: all 0.2s ease-in-out; // temporario
  transition: none;

  :disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  &:hover {
    transition: all 0.3s ease-in-out;
    background-color: ${lighten(
      0.22,
      theme.style?.buttons?.backgroundColor || theme?.color?.secondary?.pure
    )};
  }
`;

const ButtonStyled = styled.button(ButtonStyle);
const Button = ({
  title,
  onClick,
  buttonType,
  id,
  disabled,
  className = '',
  dataCy,
  testId,
  style,
}) => {
  return (
    <ButtonStyled
      className={className}
      id={id}
      type={buttonType}
      disabled={disabled}
      onClick={onClick}
      data-cy={dataCy}
      data-testid={testId}
      style={{ ...style }}
    >
      {title}
    </ButtonStyled>
  );
};

export default Button;
