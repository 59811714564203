import cookies from 'js-cookie';

export const getAnalyticsGclid = () => {
  let gclid = '';

  if (typeof window !== 'undefined') {
    const googleClientIdCookie = cookies?.get('_gcl_aw');
    const splittedGoogleClientIdCookie = googleClientIdCookie?.split('.');

    if (splittedGoogleClientIdCookie?.length > 2) {
      gclid = splittedGoogleClientIdCookie[2];
    }
  }

  return gclid;
};
