import styled, { css } from 'styled-components';
import { convertHexToRGBA } from 'utils/convertHexToRgba';

export const Wrapper = styled.div`
  ${({ theme, type }) => css`
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    img:last-child {
      cursor: pointer;
    }

    dsb-icon:last-child {
      cursor: pointer;
    }

    ${!!alertBoxModifiersByType[type] && alertBoxModifiersByType[type](theme)}
    ${theme?.data?.isThemeUsingAllDsTokens &&
    alertBoxModifiersByType.isThemeUsingAllDsTokens(theme, type)}
  `}
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 16px;
  display: flex;
  box-sizing: border-box;
`;

export const MessageGroup = styled.div`
  display: flex;
  flex-direction: column;

  dsb-typography:first-child {
    margin-bottom: 4px;
  }

  dsb-typography[id='call-center-message'] {
    margin-top: 16px;

    a {
      color: var(--neutral-color-high-contrast);
    }
  }
`;

export const ErrorTitle = styled.p`
  ${({ theme }) => css`
    font-family: ${theme?.font?.family?.primary};
    margin: 0;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    font-weight: bold;
  `}
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    font-family: ${theme?.font?.family?.primary};
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: #bcbcbc;
  `}
`;

const alertBoxModifiersByType = {
  isThemeUsingAllDsTokens: (theme, type) => css`
    padding: ${theme.spacing.xxs};

    ${Content} {
      padding: 0 ${theme.spacing.xxxs} 0 ${theme.spacing.insetNano};
    }

    ${MessageGroup} {
      dsb-typography:first-child {
        margin-bottom: ${theme.spacing.insetQuarck};
      }

      dsb-typography[id='call-center-message'] {
        margin-top: ${theme.spacing.xxxs};

        a {
          color: ${theme.color.neutral.highContrast};
        }
      }
    }

    ${type === 'error' &&
    css`
      border-color: ${theme.color.feedback.error.pure};
    `}
  `,
  error: (theme) => css`
    background-color: ${convertHexToRGBA(
      theme.color.feedback.error.pure,
      0.08
    )};
    border: 0.5px solid ${theme.style.error?.errorMsg?.color};
  `,
  info: () => css`
    background: rgba(166, 122, 8, 0.25);
    border: 0.5px solid #f2b20c;

    img:first-child {
      color: #f2b20c;
      background: #f2b20c;
    }
  `,
  success: () => css`
    background: rgba(4, 140, 61, 0.24);
    border: 1px solid var(--light-feedback-sucess-low-contrast, #bbfad5);

    img:first-child {
      color: #f2b20c;
      background: #f2b20c;
    }
  `,
};
