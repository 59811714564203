import { api } from 'services/repositories/config/base-api';

import { logger } from 'adapters/Logger';

export interface SendDataToAnalyticsParams {
  type: string;
  url: string;
  email: string;
  source: string;
  seller: string;
  system: string;
  clientId: string;
}

export const sendDataToAnalytics = async (
  data: SendDataToAnalyticsParams
): Promise<void> => {
  try {
    await api.post('/api/v1/analytics', data);
  } catch (error) {
    logger.error(error);
  }
};
