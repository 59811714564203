import { apiBase } from './base';

export async function sendLeadForm(data) {
  const normalizeUrl = `/Lead`;

  const aditionalInfos = {
    title: 'sendLeadForm() on /actions',
  };

  try {
    const response = await apiBase(
      {
        url: normalizeUrl,
        method: 'POST',
        data,
      },
      aditionalInfos
    );

    return response?.data;
  } catch (err) {
    throw err?.response?.data;
  }
}
