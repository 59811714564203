import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
  `}
`;

export const ShieldContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    dsb-icon {
      margin-right: ${theme.spacing.xxxs};
    }
  `}
`;

export const GooglePoliticsAndTermsContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    margin-top: ${theme.spacing.lg};

    a {
      color: ${theme.color.neutral.highContrast};
    }
  `}
`;

export const SealsContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    margin-top: ${theme.spacing.lg};

    img {
      width: ${theme.spacing.xl};

      &:first-child {
        margin-right: ${theme.spacing.xxs};
      }
    }
  `}
`;
