import { api } from '../config/base-api';

export async function validateTrialFluxByLeadId(leadId: string): Promise<{
  canPurchaseTrial: boolean;
}> {
  const url = `/api/v1/lead/${leadId}/validate-trial`;

  const { data } = await api.get<{ canPurchaseTrial: boolean }>(url);

  return data;
}
