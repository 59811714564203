import styled, { css } from 'styled-components';

import * as StepsStyles from 'componentsV2/Steps/styles';
import * as ErrorBoxStyles from 'componentsV2/ErrorBox/styles';

const LeadFormModifiers = {
  updateComponent: (theme) => css`
    max-width: ${theme.style.form.maxWidth};

    ${ErrorBoxStyles.Wrapper} {
      margin-bottom: 24px;
    }

    ${StepsStyles.Wrapper} {
      margin-bottom: 36px;
    }

    .titleActionLead {
      margin-bottom: 48px;

      & h1,
      h2 {
        margin: 0;
        font-weight: bold;
        max-width: 284px;
      }
    }

    .formErrorContainer {
      margin: 0;

      .formLeadError {
        margin-top: 48px;
      }
    }

    #continue-button {
      max-width: 100%;
      margin: 50px auto 0;
    }

    #inputContainer_name {
      margin-top: 12px;
    }

    .box-check-politica {
      margin-bottom: 0;

      .container-check-politica {
        p {
          letter-spacing: normal;
          a {
            text-decoration: underline;
          }
        }
      }
    }

    .container-check-politica {
      padding-left: 24px !important;

      .checkmark-check-politica {
        width: 12px;
        height: 12px;
        border-radius: 4px;
        border: 1px solid #c4c4c4;

        &::after {
          top: 0 !important;
        }
      }
    }

    ${SecurityPlaceMessageBox} {
      margin: 24px 0 50px;
    }

    ${!!LeadFormModifiers[theme.data.product.name] &&
    LeadFormModifiers[theme.data.product.name]()}
  `,
  'Meu Sucesso': () => css`
    & h1 {
      display: none !important;
    }

    h2 {
      max-width: 100% !important;
    }
  `,
};

export const LeadFormStyled = styled.div`
  ${({ theme, hideForm }) => css`
    background-color: ${theme?.color?.primary?.pure ||
    theme.style?.backgroundColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;

    dsb-text-field {
      display: block;
      margin-bottom: 16px;
    }

    .titleActionLead {
      text-align: center;
    }

    form {
      width: 100%;
      input {
        background-image: ${hideForm ? 'none' : ''};
      }
    }
    h1 {
      font-weight: ${theme?.style?.lead?.formTitle?.fontWeight};
      margin-bottom: ${theme?.style?.lead?.formTitle?.marginBottom};
      color: ${theme?.style?.lead?.formTitle?.color};
      text-align: ${theme?.style?.lead?.formTitle?.textAlign};
      font-size: ${theme?.style?.lead?.formTitle?.fontSize};
      line-height: ${theme?.style?.lead?.formTitle?.lineHeight};
      font-family: ${theme?.style?.lead?.formTitle?.fontFamily};
    }
    h2 {
      font-family: ${theme?.style?.lead?.formSubtitle?.fontFamily};
      font-weight: ${theme?.style?.lead?.formSubtitle?.fontWeight};
      font-size: ${theme?.style?.lead?.formSubtitle?.fontSize};
      text-align: ${theme?.style?.lead?.formSubtitle?.textAlign};
      color: ${theme?.style?.lead?.formTitle?.color};
    }
    .formErrorContainer {
      text-align: center;
      margin: 10px auto 30px;
      span {
        margin: ${theme?.style?.error?.formErrorMsg?.margin};
        font-size: ${theme?.style?.error?.formErrorMsg?.fontSize};
        padding-top: ${theme?.style?.error?.formErrorMsg?.paddingTop};
        display: ${theme?.style?.error?.formErrorMsg?.display};
        color: ${theme?.style?.error?.formErrorMsg?.color};
        text-align: ${theme?.style?.error?.formErrorMsg?.textAlign};
      }
    }
    /* #inputContainer_name input{
    text-transform: capitalize;
  } */
    .titleActionLead h1 {
      font-size: ${theme.style?.titleActionLead?.h1.fontSize || '15px'};
      color: ${theme.style?.titleActionLead?.h1.color || '#777'};
      margin: 0 0 20px 0;
      /* font-family: 'Muli'; */
      font-weight: ${theme.style?.titleActionLead?.h1.fontWeight || '600'};
    }

    .titleActionLead h2 {
      text-align: center;
      font-weight: normal;
      font-size: ${theme.style?.titleActionLead?.h2?.fontSize || '20px'};
    }

    /*checkbox politica*/

    .box-check-politica {
      margin: ${theme.spacing.xxxs} 0 ${theme.spacing.xs} 0;
    }

    .box-check-politica .container-check-politica {
      display: block;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .box-check-politica .container-check-politica input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .box-check-politica .container-check-politica p {
      margin: 0;
      font-size: 14px;
      color: ${theme.color.neutral.pure};
      letter-spacing: 1.4px;
    }

    .box-check-politica .container-check-politica p a {
      color: ${theme.style.lead.checkboxPolitica.textBoxPolitica.colorLink};
      text-decoration: none;
    }

    .checkmark-check-politica {
      position: absolute;
      margin: auto 0 auto 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 17px;
      box-sizing: initial;
      width: 17px;
      border-radius: ${theme.style.lead.checkboxPolitica?.checkmarkPolitica
        ?.borderRadius || '2px'};
      border: ${theme.style.lead.checkboxPolitica.checkmarkPolitica.border};
    }

    /* On mouse-over, add a background color */
    .box-check-politica
      .container-check-politica:hover
      input
      ~ .checkmark-check-politica {
      /* background-color: #ccc; */
    }

    /* When the checkbox is checked, add a background */
    .box-check-politica
      .container-check-politica
      input:checked
      ~ .checkmark-check-politica {
      background-color: ${theme.style.lead.checkboxPolitica.checkmarkPolitica
        .backgroundColor};
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark-check-politica:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .box-check-politica
      .container-check-politica
      input:checked
      ~ .checkmark-check-politica:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .box-check-politica
      .container-check-politica
      .checkmark-check-politica:after {
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 2px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      box-sizing: initial;
    }

    dsb-button ::part(button) {
      margin-top: 30px;
    }

    ${LeadFormModifiers.updateComponent(theme)}
  `}
`;

export const TitleContainer = styled.div`
  dsb-typography {
    display: inline-block;

    &:last-child {
      margin-top: 8px;
    }
  }
`;

export const SecurityPlaceMessageBox = styled.figure`
  ${({ product }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    dsb-icon {
      margin-right: 16px;
    }

    img {
      margin-right: 10px;
    }

    p {
      color: #bcbcbc;
      font-size: ${product === 'WUP' ? '14px' : '12px'};
      line-height: 24px;
      margin: 0;
    }
  `}
`;

export const FooterMessage = styled.p`
  ${({ theme }) => css`
    color: ${theme?.style?.text?.descriptionColor};
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    margin: 0;
    max-width: 465px;

    a {
      color: ${theme?.style?.text?.descriptionColor};
    }
  `}
`;
