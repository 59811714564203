import React from 'react';
import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';
import ReturnFirstCapital from '../utils/returnFirstCapital';
import CartContext from '../contexts/CartContext';
import * as validate from '../utils/inputValidate';
import router from 'next/router';
import { isTestMode } from 'utils/globalFunctions';

const DsTypography = dynamic(() => import('componentsV2/DS/Typography'), {
  ssr: false,
});

const inputModifiers = {
  updateComponent: (theme, hasError, isOutlined) => css`
    label.field-label {
      line-height: ${theme.style.fieldLabel.lineHeight};
      margin-bottom: ${theme.style.fieldLabel.marginBottom};
    }

    dsb-typography {
      margin-bottom: ${theme.style.fieldLabel.marginBottom};
      display: inline-block;
    }

    input {
      border-bottom: ${theme.style.input.borderBottom};
      border: ${theme.style.input.border};
      outline: ${theme.style.input.outline};

      &.valid {
        border-bottom: ${theme.style.input.valid.borderBottom};
        border: ${theme.style.input.valid.border};
        outline: ${theme.style.input.valid.outline};
      }

      &:focus {
        border: ${theme.style.input.focus.border};
        border-bottom: ${theme.style.input.focus.borderBottom};
        outline: ${theme.style.input.focus.outline};
      }

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        border: ${theme.style.input.focus.border};
      }
    }

    ${isOutlined && inputModifiers.isOutlined(theme)}
    ${hasError && inputModifiers.hasError(theme)}
  `,
  hasError: (theme) => css`
    input {
      border-bottom: ${theme.style.input.invalid.borderBottom};
      border: ${theme.style.input.invalid.border};
      outline: ${theme.style.input.invalid.outline};
    }
  `,
  isOutlined: (theme) => css`
    input {
      border-bottom: ${theme.style.input.outlined.borderBottom};
      border: ${theme.style.input.outlined.border};
      outline: ${theme.style.input.outlined.outline};

      &.valid {
        border-bottom: ${theme.style.input.outlined.borderBottom};
        border: ${theme.style.input.outlined.border};
        outline: ${theme.style.input.outlined.outline};
      }

      &:focus {
        border: ${theme.style.input.focus.border};
        border-bottom: ${theme.style.input.focus.borderBottom};
        outline: ${theme.style.input.focus.outline};
      }
    }
  `,
};

const InputStyle = ({ theme, hasError, isOutlined }) => css`
  position: relative;
  padding-bottom: ${theme.style.fieldWrapper.paddingBottom};
  width: ${theme.style.fieldWrapper.width};
  margin: ${theme.style.fieldWrapper.margin};
  label.field-label {
    font-weight: ${theme.style.fieldLabel.fontWeight};
    position: ${theme.style.fieldLabel.position};
    color: ${theme.style.fieldLabel.color};
    top: ${theme.style.fieldLabel.top};
    left: ${theme.style.fieldLabel.left};
    text-transform: ${theme.style.fieldLabel.textTransform};
    letter-spacing: ${theme.style.fieldLabel.letterSpacing};
    transition: ${theme.style.fieldLabel.transition};
    font-size: ${theme.style.fieldLabel.fontSize};
    z-index: ${theme.style.fieldLabel.zIndex};
    background-color: ${theme.style.fieldLabel.backgroundColor};
    margin: ${theme.style.fieldLabel.margin};
    font-family: ${theme.style.fieldLabel.fontFamily};
    display: ${theme.style.fieldLabel.display};
    padding: ${theme.style.fieldLabel.padding};
    flex-wrap: ${theme.style.fieldLabel.flexWrap};
    flex-direction: ${theme.style.fieldLabel.flexDirection};
  }
  input {
    width: ${theme.style.input.width};
    border: ${theme.style.input.border};
    border-radius: ${theme.style.input.borderRadius};
    border-bottom: ${theme.style.input.borderBottom};
    background-color: ${theme.style.input.backgroundColor};
    font-size: ${theme.style.input.fontSize};
    font-weight: ${theme.style.input.fontWeight};
    box-shadow: ${theme.style.input.boxShadow};
    color: ${theme.style.input.color};
    font-family: ${theme.style.input.fontFamily};
    transition: ${theme.style.input.transition};
    padding: ${theme.style.input.padding};
    height: ${theme.style.input.height};
    line-height: ${theme.style.input.lineHeight};
    padding-left: ${theme.style.input.paddingLeft};
    padding-top: ${theme.style.input.paddingTop};
    margin-top: ${theme.style.input.marginTop};
    box-sizing: ${theme.style.input.boxSizing};
    outline: none;

    @media (max-width: 767px) {
      font-size: 16px;
    }

    /* &:focus{
      height: ${theme.style.input.focus.height};
      border-radius: ${theme.style.input.focus.borderRadius};
      box-shadow: ${theme.style.input.focus.boxShadow};
      background-color: ${theme.style.input.focus.backgroundColor};
      border: ${theme.style.input.focus.border};
      color: ${theme.style.input.focus.color};
      outline: ${theme.style.input.focus.outline};
    } */
    &.valid {
      border-bottom: ${theme.style.input.valid.borderBottom};
      border: ${theme.style.input.valid.border};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      border-radius: ${theme.style.input.autofill.borderRadius};
      -webkit-text-fill-color: ${theme.style.input.autofill
        .webkitTextFillColor};
      -webkit-box-shadow: ${theme.style.input.autofill.webkitBoxShadow};
      transition: ${theme.style.input.autofill.transition};
    }
  }
  &.active {
    input {
      border-color: ${theme.style.input.active.borderColor || 'white'};
    }
  }

  .input-group {
    position: relative;

    .icon-input {
      background-color: ${theme.style.input.backgroundColor};
      width: 44px !important;
      height: 20px !important;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
      padding: inherit;

      div {
        width: 30px !important;
        height: 100% !important;
      }

      svg {
        position: relative;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .error-container {
    width: 100%;
    padding: 0;
    span {
      font-size: ${theme.style.error.errorMsg.fontSize};
      display: ${theme.style.error.errorMsg.display};
      max-width: ${theme.style.error.errorMsg.maxWidth};
      color: ${theme.style.error.errorMsg.color};
      margin: ${theme.style.error.errorMsg.margin};
      padding: ${theme.style.error.errorMsg.padding};
      position: ${theme.style.error.errorMsg.position};
    }
  }

  ${inputModifiers.updateComponent(theme, hasError, isOutlined)}
`;

const IconInput = styled.span`
  position: absolute;
  top: 0;
  right: 15px;
  transform: translate(0, 50%);
`;

const InputStyled = styled.div([InputStyle]);

const patterns = {
  name: '/^([a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇ]{2,}(s|$)){2,}/',
};

export default class Input extends React.Component {
  state;
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      mask: this.props.mask,
      errorMessage: null,
      value: '',
      inputType: null,
      fieldLabel: null,
      active: false,
      valid: null,
      testMode: false,
    };
  }

  componentDidMount() {
    if (isTestMode(router.query?.test) && this.props?.hideOnTest) {
      this.setState({ testMode: true });
    } else {
      this.setState({ testMode: false });
    }

    const { fieldName, fieldtype, htmlInputType } = this.props;

    if (fieldtype != null) {
      this.setState({ fieldtype: fieldtype?.toUpperCase() });
    } else
      throw {
        err: 'Input component need to have the prop fieldtype defined',
      };

    if (fieldName != null) {
      this.setState({
        fieldName: fieldName?.toUpperCase(),
      });
    } else
      throw {
        err: 'Input component need to have the prop fieldName defined',
      };

    if (htmlInputType == null)
      throw {
        err: 'Input component need to have the prop htmlInputType defined',
      };

    // this.validateInput = () => validateInput()
    if (this.props.value) {
      this.setState({ value: this.props.value });
      this.validateInput();
    }
  }

  onBlur() {
    this.setState({ value: this.state.value.trim(), active: false });
    if (this.state.value != '') {
      this.validateInput();
    }
    if (this.state.value === '') {
      this.setState((prev) => {
        return {
          ...prev,
          valid: false,
        };
      });
    }

    if (this.props?.onBlur) this.props?.onBlur();
  }

  validateInput() {
    const validateFunction = validate.inputValidate;
    const validateObj = validateFunction(
      this.state.fieldtype,
      this.state.value,
      this.context.productTheme.data.lang
    );
    this.setState({
      errorMessage: validateObj.errorMessage,
      valid: validateObj.valid,
    });
    return validateObj;
  }

  onFocus() {
    this.setState({
      active: true,
    });
  }

  clearInput() {
    this.setState({ value: '' });
  }

  formatValue(value) {
    this.setState({
      active: true,
    });
    if (value) {
      let formatted;
      switch (this.state.fieldtype) {
        case 'REGISTRY':
          const numbers = value.replace(/\D/g, '');
          const length = numbers.length;
          if (length <= 14) {
            const isCpf = length <= 11;
            const mask = isCpf ? '999.999.999-999' : '99.999.999/9999-99';
            if (isCpf)
              value = `${numbers.substr(0, 3)}.${numbers.substr(
                3,
                3
              )}.${numbers.substr(6, 3)}-${numbers.substr(9, 2)}`;
            else
              value = `${numbers.substr(0, 2)}.${numbers.substr(
                2,
                3
              )}.${numbers.substr(5, 3)}/${numbers.substr(
                8,
                4
              )}-${numbers.substr(12, 2)}`;
            this.setState({ mask });
            formatted = value;
          } else {
            formatted = this.state.value;
          }
          break;
        case 'EMAIL':
          formatted = value.replace(/ /g, '').trim().toLowerCase();
          break;
        case 'NAME':
          const splitted = value.replace(/\s{2,}/g, ' ').split(' ');
          formatted = splitted
            .map((x) => ReturnFirstCapital(x))
            .join(' ')
            .toString();
          break;
        case 'PHONE':
          let newState = {
            mask: '?9 99999-9999999',
            value: value,
          };
          if (this.context.area == 'LATAM') newState.mask = '99999 99999';
          if (value.startsWith('+')) {
            if (value.replace(' ', '').indexOf('+55') == 0) {
              value = value
                .replace(' ', '')
                .replace('+55', '')
                .replace(/[^\d]+/g, '');
              const ddd = value.substring(0, 2);
              const prefix = value.substring(2, 7);
              const posfix = value.substring(7);
              value = ddd + ' ' + prefix + '-' + posfix;
              newState.value = value;
            } else {
              const mask = '?999999999999999';
              newState.mask = mask;
            }
          }
          this.setState(newState);
          formatted = value;
          break;
        case 'CARD_EXPIRE_DATE':
          formatted = value.replace(/\D/g, '').trim();
          if (formatted.length > 4)
            formatted =
              formatted.substring(0, 2) +
              '/' +
              formatted.substring(formatted.length - 2, formatted.length);
          else formatted = value;
          break;
        case 'NUMERO':
          /* if (!value.match(/^[0-9]+$/)) {
            return formatted = value
          } */
          formatted = value.replace(/\D/g, '').trim();
          formatted = value.replace(' ', '').trim();
          break;
        default:
          formatted = value;
          break;
      }

      if (this.props?.onChange) this?.props?.onChange(formatted);

      this.setState({ value: formatted });
    } else this.setState({ value });
  }

  render() {
    return (
      <InputStyled
        ref={this.myRef}
        style={{ ...this.props.style }}
        id={`inputContainer_${this.props.fieldName.toLowerCase()}`}
        className={`input-wrapper ${this.state.active ? 'active' : ''}`}
        hasError={!!this.state.errorMessage || !!this.props?.errorMessage}
        isOutlined={!!this.props?.isOutlined}
      >
        <DsTypography
          htmlFor={this.props.fieldName}
          className="field-label"
          component="paragraph"
          size="small-bold"
          color="neutral-pure"
        >
          {this.props.fieldLabel}
        </DsTypography>

        <div className="input-group">
          <InputMask
            data-testid={this.props.fieldName}
            id={this.props.fieldName}
            name={this.props.fieldName}
            className={`data-hj-whitelist ${
              this.state.valid == true ? 'valid' : ''
            }`}
            maskChar=""
            mask={this.props.mask || this.state.mask}
            formatChars={this.props.formatChars}
            placeholder={this.props.inputPlaceholder}
            readOnly={this.props.readonly}
            type={this.state?.testMode ? 'password' : this.props.htmlInputType}
            autoComplete={this.props.autocomplete}
            maxLength={this.props.maxLength}
            value={this.state.value}
            onChange={(e) => this.formatValue(e.target.value)}
            onKeyUp={this.props.onKeyUp}
            onBlur={() => this.onBlur()}
            data-cy={this.props.dataCy}
            onFocus={() => this.onFocus()}
          ></InputMask>

          {!!this.props?.icon && (
            <IconInput className="icon-input">{this.props?.icon}</IconInput>
          )}
        </div>

        <div
          data-testid="errorContainer"
          id={'errorContainer'}
          className="error-container"
        >
          {(this.state.errorMessage || this.props?.errorMessage) != null && (
            <span
              data-cy={this.props.dataCyErrorMessage}
              id={`error_${this.props.fieldtype.toLowerCase()}`}
            >
              <DsTypography
                component="paragraph"
                size="small"
                color="feedback-error-pure"
              >
                {this.state.errorMessage || this.props?.errorMessage}
              </DsTypography>
            </span>
          )}
        </div>
      </InputStyled>
    );
  }
}
