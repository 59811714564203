import React, { useEffect, useRef, useContext } from 'react';
import dynamic from 'next/dynamic';
import smoothscroll from 'smoothscroll-polyfill';
import CartContext from 'contexts/CartContext';

const DsTypography = dynamic(() => import('componentsV2/DS/Typography'), {
  ssr: false,
});

const DsIcon = dynamic(() => import('componentsV2/DS/Icon'), {
  ssr: false,
});

import {
  Wrapper,
  Content,
  MessageGroup,
  ErrorTitle,
  ErrorMessage,
} from './styles';

import IconAlert from 'componentsV2/Icons/IconAlert';
import IconCheckCircle from 'componentsV2/Icons/IconCheckCircle';

/**
 *
 * @deprecated
 * use DS/FeedbackBox instead this
 */
const ErrorBox = ({
  errorMessage = '',
  errorTitle = 'Mensagem de erro',
  scrollToTop = false,
  type = 'error',
  onClose = null,
  showCallCenterLink = false,
  ...props
}) => {
  const context = useContext(CartContext);

  const componentRef = useRef(null);

  if (typeof window !== 'undefined') {
    // kick off the polyfill!
    smoothscroll.polyfill();
  }

  const executeScroll = () =>
    scrollToTop
      ? window?.scrollTo({
          behavior: 'smooth',
          top: 0,
          left: 0,
        })
      : componentRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });

  useEffect(() => {
    setTimeout(() => {
      !!componentRef && executeScroll();
    }, 200);
  }, []);

  return (
    <Wrapper {...props} type={type} ref={componentRef}>
      <DsIcon
        icon={type === 'success' ? 'check_circle' : 'warning_circle'}
        color={
          type === 'success' ? 'feedback-success-pure' : 'feedback-error-pure'
        }
      />

      <Content>
        <MessageGroup>
          <DsTypography
            component="paragraph"
            size="small-bold"
            color="neutral-pure"
          >
            {errorTitle}
          </DsTypography>
          <DsTypography component="paragraph" size="small" color="neutral-high">
            {errorMessage}
          </DsTypography>
          {showCallCenterLink && (
            <DsTypography
              id="call-center-message"
              component="paragraph"
              size="small"
              color="neutral-high"
            >
              Caso tenha dúvidas sobre, acesse nossa{' '}
              <a
                target="_blank"
                href={context?.productTheme?.data?.faqLink}
                rel="noreferrer"
              >
                central de atendimento
              </a>
              .
            </DsTypography>
          )}
        </MessageGroup>
      </Content>
      {!!onClose && (
        <DsIcon icon="x" onClick={onClose} data-testid="close-icon" />
      )}
    </Wrapper>
  );
};

export default ErrorBox;
