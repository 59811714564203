import smoothscroll from 'smoothscroll-polyfill';

export default function scrollTo(offsetTop) {
  if (typeof window !== 'undefined') {
    // kick off the polyfill!
    smoothscroll?.polyfill();

    window.scrollTo({
      top: offsetTop || 270,
      behavior: 'smooth',
    });
  }
}
