import { sendDataToAnalytics as sendDataToAnalyticsService } from 'services/third-party';

export const sendDataToAnalytics = async (data, url, event) => {
  try {
    await sendDataToAnalyticsService({
      type: event,
      url: `/assine/${url}`,
      email: data.userEmail,
      source: data.source,
      seller: data.seller,
      system: data.system,
      clientId: data._ga,
    });
  } catch (error) {
    console.error(
      `sendDataToAnalytics (/assine/${url}) error: `,
      JSON.stringify(error)
    );
  }
};
