// eslint-disable-next-line object-shorthand
import * as Yup from 'yup';
import { inputValidate } from 'utils/inputValidate';

import { validatePhone } from 'utils/PhoneNumberValidation';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useContext } from 'react';
import CartContext from 'contexts/CartContext';

export function useValidations() {
  const context = useContext(CartContext);

  const validations = inputValidate;

  const email = Yup.string().test({
    email: 'validator-custom-email',
    test(value) {
      return !validations('EMAIL', value || '', context.productTheme.data.lang)
        .valid
        ? this.createError({
            message: validations(
              'EMAIL',
              value || '',
              context.productTheme.data.lang
            ).errorMessage,
            path: 'email',
          })
        : true;
    },
  });

  const name = Yup.string().test({
    name: 'validator-custom-name',
    test(value) {
      return !validations('NAME', value || '', context.productTheme.data.lang)
        .valid
        ? this.createError({
            message: validations(
              'NAME',
              value || '',
              context.productTheme.data.lang
            ).errorMessage,
            path: 'name',
          })
        : true;
    },
  });

  const required = Yup.string().required('Este campo é obrigatório');

  const cpf = Yup.string().test({
    name: 'validator-custom-cpf',
    test(value) {
      return !validations('CPF', value || '', context.productTheme.data.lang)
        .valid
        ? this.createError({
            message: validations(
              'CPF',
              value || '',
              context.productTheme.data.lang
            ).errorMessage,
            path: 'cpf',
          })
        : true;
    },
  });

  const subscriptionCpf = Yup.string().test({
    name: 'validator-custom-subscription-cpf',
    test(value) {
      return !validations('CPF', value || '', context.productTheme.data.lang)
        .valid
        ? this.createError({
            message: validations(
              'CPF',
              value || '',
              context.productTheme.data.lang
            ).errorMessage,
            path: 'subscriptionCpf',
          })
        : true;
    },
  });

  const phone = Yup.string()
    .required(
      'Insira o DDD e um número de celular válido. Caso seja de fora do Brasil, selecione a bandeira no ícone ao lado.'
    )
    .test(
      'is-possible',
      'Insira o DDD e um número de celular válido. Caso seja de fora do Brasil, selecione a bandeira no ícone ao lado.',
      (phoneNumber) => {
        return validatePhone(
          phoneNumber || '',
          parsePhoneNumber(phoneNumber || '')?.country || 'US'
        );
      }
    );

  const phoneMZ = Yup.string()
    .required(
      'Seleccione a bandeira do seu país no ícone ao lado e insira um número de telemóvel válido com DDD'
    )
    .test(
      'is-possible',
      'Seleccione a bandeira do seu país no ícone ao lado e insira um número de telemóvel válido com DDD',
      (phoneNumber) => {
        return validatePhone(
          phoneNumber || '',
          parsePhoneNumber(phoneNumber || '')?.country || 'US'
        );
      }
    );

  const cardExpirateDate = Yup.string().test({
    name: 'validator-expirate-date',
    test(value) {
      return !validations(
        'CARD_EXPIRE_DATE',
        value || '',
        context.productTheme.data.lang
      ).valid
        ? this.createError({
            message: validations(
              'CARD_EXPIRE_DATE',
              value || '',
              context.productTheme.data.lang
            ).errorMessage,
            path: 'cardExpireDate',
          })
        : true;
    },
  });

  const secondCardExpirateDate = Yup.string().test({
    name: 'second-card-validator-expirate-date',
    test(value) {
      return !validations(
        'CARD_EXPIRE_DATE',
        value || '',
        context.productTheme.data.lang
      ).valid
        ? this.createError({
            message: validations(
              'CARD_EXPIRE_DATE',
              value || '',
              context.productTheme.data.lang
            ).errorMessage,
            path: 'secondCardExpireDate',
          })
        : true;
    },
  });

  const cardNumber = Yup.string().test({
    name: 'validator-card-number',
    test(value) {
      return !validations(
        'CARD_NUMBER',
        value || '',
        context.productTheme.data.lang
      ).valid
        ? this.createError({
            message: validations(
              'CARD_NUMBER',
              value || '',
              context.productTheme.data.lang
            ).errorMessage,
            path: 'cardNumber',
          })
        : true;
    },
  });

  const secondCardNumber = Yup.string().test({
    name: 'second-card-validator-card-number',
    test(value) {
      return !validations(
        'CARD_NUMBER',
        value || '',
        context.productTheme.data.lang
      ).valid
        ? this.createError({
            message: validations(
              'CARD_NUMBER',
              value || '',
              context.productTheme.data.lang
            ).errorMessage,
            path: 'secondCardNumber',
          })
        : true;
    },
  });

  const cardCvv = Yup.string().test({
    name: 'validator-card-cvv',
    test(value, yupContext) {
      const cardBrand = yupContext.parent?.cardBrand;
      const isFourDigitsCard = cardBrand === 'american_express';
      /*
        Por segurança, é interessante forçar apenas os cartões que são
        exceção e deixar os demais como já funciona
      */
      const length = isFourDigitsCard ? 4 : null;

      return !validations(
        'CARD_CVV',
        value || '',
        context.productTheme.data.lang,
        length
      ).valid
        ? this.createError({
            message: validations(
              'CARD_CVV',
              value || '',
              context.productTheme.data.lang,
              length
            ).errorMessage,
            path: 'cvv',
          })
        : true;
    },
  });

  const secondCardCvv = Yup.string().test({
    name: 'second-card-validator-card-cvv',
    test(value, yupContext) {
      const cardBrand = yupContext.parent?.secondCardBrand;
      const isFourDigitsCard = cardBrand === 'american_express';
      /*
        Por segurança, é interessante forçar apenas os cartões que são
        exceção e deixar os demais como já funciona
      */
      const length = isFourDigitsCard ? 4 : null;

      return !validations(
        'CARD_CVV',
        value || '',
        context.productTheme.data.lang,
        length
      ).valid
        ? this.createError({
            message: validations(
              'CARD_CVV',
              value || '',
              context.productTheme.data.lang,
              length
            ).errorMessage,
            path: 'secondCardCvv',
          })
        : true;
    },
  });

  return {
    email,
    name,
    required,
    cpf,
    subscriptionCpf,
    phone,
    cardExpirateDate,
    cardCvv,
    cardNumber,
    phoneMZ,
    secondCardNumber,
    secondCardExpirateDate,
    secondCardCvv,
  };
}
