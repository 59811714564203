import styled from 'styled-components';

const GenericWrapperMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px auto;
  text-align: center;
  a {
    color: inherit;
  }
`;

export const SecurityPlaceMessageBox = styled(GenericWrapperMessage)`
  gap: 8px;
  margin-top: 24px;
`;

export const FooterMessage = styled(GenericWrapperMessage)`
  margin-top: 72px;
`;

export const Wrapper = styled.div`
  max-width: 465px;
  margin: 0 auto;
`;

export const Form = styled.form`
  margin-top: 40px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  dsb-button {
    margin-top: 16px;
  }
`;

export const ErrorBoxContainer = styled.div`
  margin-top: 40px;
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  text-align: center;
  margin-bottom: 56px;
`;
