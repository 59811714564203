import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { parsePhoneNumber } from 'libphonenumber-js/max';

function formatMaskNumber(number) {
  if (!number) return '';

  return formatPhoneNumberIntl(number);
}

function filterBrPhone(phone) {
  if (phone) {
    if (phone.indexOf('+') !== -1) {
      return phone;
    }
    return `+55${phone}`;
  }
  return phone;
}

function validatePhone(phone, cctld) {
  let validNumber = false;
  try {
    const phoneNumber = parsePhoneNumber(phone, cctld);

    const isValidNumber = phoneNumber.isValid() ? phoneNumber?.number : null;

    validNumber = isValidNumber;
  } catch (error) {}
  return validNumber;
}

export { filterBrPhone, validatePhone, formatMaskNumber };
